
import { useEffect, useState } from "react"
import { METEO_CONSTANTS } from "../../modules/constants"
import { setSpotWeather } from "../../modules/requestHelper"
import { setSpotWeatherOld } from "../../modules/requestHelper"
import { useTranslation } from "react-i18next"
import { useAccessContext } from "../../modules/AccessContext"
import { defaultColorOfParam, defaultDashArrayOfParam, isLineParam } from "../../modules/layout"

const SpotWeatherTeaser = (accessContext, t, loopIndex, update) => {
    const [weather, setWeather] = useState(undefined)
  //   console.log(accessContext.accessContext)
  // console.log(loopIndex)
  useEffect(() => {
    // init component with spot weather data
    if (loopIndex) {
      let spotweatherRequests =
        accessContext.configuration?.spotweather?.spotweatherRequests
      let requestIndex = loopIndex.substring(0, loopIndex.indexOf("."))
      let locationIndex = loopIndex.substring(loopIndex.indexOf(".") + 1)
  
      let paramsList = []
      //console.log(accessContext.accessContext)
      let requestParameters =
        spotweatherRequests[requestIndex]?.spotweatherParameters.nodes
      if (requestParameters.length > 0) {
        let params = requestParameters.filter(
          (p) => p.type === METEO_CONSTANTS.paramDescriptionType
        )
  
        if (params && params.length > 0) {
          let paramColumns = [...new Array(METEO_CONSTANTS.maxParams)].map(
            (i, index) => params[0]["param" + (index + 1)]
          )
  
          paramColumns.forEach((col) => {
            if (col !== undefined && col[0] !== undefined ) {
              paramsList.push(col[0])
            }
          })
        }
        setSpotWeatherOld(
          accessContext,
          spotweatherRequests,
          requestIndex,
          paramsList.length,
          locationIndex,
          t,
          setWeather,
          undefined
          ) 
      }
    }
  }, [accessContext, t, loopIndex, update])
  return {weather}
  }
  
  function SpotWeather ( gotoArgs, update ) {
    const { t } = useTranslation()

    const msgPrefix = t("error_data_api_request")
  //   const [params, setParams] = useState([]) // params from spot weather configuration
    const [legend, setLegend] = useState([])
    const [weather, setWeather] = useState(undefined) // current weather
  
    const { accessContext, setAccessContext } = useAccessContext()
  
    const [msg, setMessage] = useState("")
    const [snack, setSnack] = useState({ open: false })
    const handleCloseSnack = () => {
      setSnack((state) => ({ ...state, open: false }))
    }
  
    useEffect(() => {
      // init component with spot weather data
      let errorHandler = (result) => {
        setMessage(msgPrefix + result.toString())
        setSnack((state) => ({ ...state, open: true }))
      }
  
      let paramsList = []
      let legendData = []
      if (gotoArgs) {
        //console.log(accessContext)
        let spotweatherRequests =
          accessContext.configuration?.spotweather?.spotweatherRequests
        let requestIndex = gotoArgs.loopIndex.substring(
          0,
          gotoArgs.loopIndex.indexOf(".")
        )
        let locationIndex = gotoArgs.loopIndex.substring(
          gotoArgs.loopIndex.indexOf(".") + 1
        )
  
        let requestParameters =
          spotweatherRequests[requestIndex]?.spotweatherParameters.nodes
        if (requestParameters.length > 0) {
          let params = requestParameters.filter(
            (p) => p.type === METEO_CONSTANTS.paramDescriptionType
          )
          if (params && params.length > 0) {
            let paramColumns = [...new Array(METEO_CONSTANTS.maxParams)].map(
              (i, index) => params[0]["param" + (index + 1)]
            )
            paramColumns.forEach((pCol) => {
              if (pCol !== undefined && pCol[0] !== undefined) {
                paramsList.push({
                  key: pCol[0],
                  unit: pCol[2],
                  transformation: undefined,
                })
              }
              if (isLineParam(pCol[0])) {
                  legendData.push({
                    name: t("table_header_param_" + pCol[0]),
                    labels: { fontSize: 10 },
                    symbol: {
                      fill: defaultColorOfParam(pCol[0]),
                      type:
                        defaultDashArrayOfParam(pCol[0]) === undefined
                          ? "square"
                          : "minus",
                    },
                  })
                }
            })
          //   setParams(paramsList)
            setLegend(legendData)
          }
        }
  
      //   console.log(paramsList)
  
        setSpotWeather(
          accessContext,
          setAccessContext,
          spotweatherRequests,
          requestIndex,
          paramsList.length,
          locationIndex,
          t,
          setWeather,
          errorHandler
        )
      }
    }, [accessContext, setAccessContext, msgPrefix, t, gotoArgs, update])
    //console.log(weather)
    return {weather, msg, snack, handleCloseSnack, legend}
  }
  
  export { SpotWeatherTeaser, SpotWeather }
