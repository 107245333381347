import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { scaleUtc } from "d3-scale"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory"
import {
  defaultColorOfParam,
  defaultDashArrayOfParam,
  defaultInterpolationVictory,
  isLineParam,
  isWWParam,
  lineParamTransformation,
  xAxisTimeFormat,
} from "../../modules/layout"
import { CustomFlyout } from "../SpotWeatherModule/CustomFlyout"
import useStyles from "./styles"
import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
import { useAccessContext } from '../../modules/AccessContext'
const SpotWeatherDataChartMeteo = ({
  values = [],
  params = [],
  warnings = [],
  colors = [],
  legend,
}) => {
  const myStyles = useStyles()

  const { t } = useTranslation()
  const accessContext = useAccessContext()

  const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi")

   for (let i = 0; i < params.length; i++) {
   if (params[i].includes("P-WEATHER")) {
    for (let j = 0; j < params.length; j++) {
      if (params[j].includes("WW")) {
        params.splice(j,1)
      }
    }
   } 

   }

     // let cloudsIndex
     if (params[params.length-1][0] === "NCL") {
      // cloudsIndex = params.length
       params.pop()
     }
  let lineValue = (d, pIndex, pShort) => {
    let rawValue = d["param" + (pIndex + 1)]
    let parsed = parseFloat(rawValue)

    if (isNaN(parsed)) {
      return null
    } else {
      return lineParamTransformation(pShort, parsed)  
  }
  }

  let xAxisFormat = (timeValue) => xAxisTimeFormat(timeValue, t)

  const chartWidth = 500
  const chartHeight = 250
  const axis0Left = 50
  const relativeIconHeight = 0.98
  const domainHeightFactor = 1.25

  const layoutChart2 = { top: 0, bottom: 40, height: 20, legendOffset: 90 }

  const [wwParamIndex, setWWParamIndex] = useState(undefined)
  const [axis, setAxis] = useState([])
  // const [legend, setLegend] = useState([])

  const [zoom, setZoom] = useState({})
  let handleZoomVoronoi = function (domain) {
    setZoom({ zoomDomain: domain })
  }
  let handleZoomBrush = function (domain) {
    setZoom({
      zoomDomain: {
        x: domain.x,
        y: [domain.y[0], domain.y[1] * domainHeightFactor],
      },
    })
  }
  let initialChecked = {}
  params.forEach((p) => {
    initialChecked = { ...initialChecked, [p[0]]: true }
  })
  const [checked, setChecked] = useState(initialChecked)

  const handleCheckChange = (name) => {
    setChecked({ ...checked, [name]: !checked[name] })
  }

  const [firstCheckedLineParam, setFirstCheckedLineParam] = useState(undefined)

if (accessContext.accessContext.payload.access.includes("local_time")) {
  for (let i=0;i<values.length;i++) {
    values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
  }
  }
  useEffect(() => {
    // set dependant axis, wwParam
    let axisData = []
    let firstCheckedLineParam = undefined
    let wwParamIndex = undefined
    params.forEach((p, pIndex) => {
      if (isLineParam(p[0]) && checked[p[0]]) {
        let unit = p[0] === "VV" && p[2] === "m" ? "km" : p[2]
        let transformation = p.transformation
        
        console.log(params)
        //          let existingAxis = axisData.filter(ax => ax.label === unit && ax.transformation === transformation)
        let existingAxis = axisData.filter(
          (ax) => ax.transformation === transformation
        )
        if (existingAxis.length === 0) {
          axisData.push({
            label: unit,
            transformation: transformation,
            orientation: axisData.length === 0 ? "left" : "right",
            offsetX:
              axisData.length === 0 ? axis0Left : 10 + axisData.length * 40,
          })
        }
        firstCheckedLineParam = firstCheckedLineParam
          ? firstCheckedLineParam
          : p[0]
      }
      if (isWWParam(p[0])) {
        wwParamIndex = pIndex
      }
    })
    setAxis(axisData)
    setFirstCheckedLineParam(firstCheckedLineParam)
    setWWParamIndex(wwParamIndex)
  }, [params, checked])

  return (
    <div className={myStyles.chartAndLegend}>
      <div className={myStyles.chartContainer}>
        <VictoryChart
          className={myStyles.charts}
          width={chartWidth}
          height={chartHeight}
          scale={{ x: scaleUtc() }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomVoronoiContainer
              voronoiDimension='x'
              zoomDimension='x'
              zoomDomain={zoom.zoomDomain}
              onZoomDomainChange={handleZoomVoronoi}
              className={myStyles.charts}
            />
          }
        >
          {params.map(
            (p, pIndex) =>
              checked[p[0]] &&
              isLineParam(p[0]) && (
                <VictoryLine
                  key={p[0]}
                  interpolation={defaultInterpolationVictory}
                  style={{
                    data: {
                      stroke: defaultColorOfParam(p[0]),
                      strokeWidth: 1,
                      strokeDasharray: defaultDashArrayOfParam(p[0]),
                    },
                  }}
                  data={values}
                  name={p[0]}
                  x='timeAsDate'
                  y={(d) => lineValue(d, pIndex, p[0])}
                  labels={() => " "}
                  labelComponent={
                    <VictoryTooltip
                      flyoutComponent={
                        <CustomFlyout
                          params={params}
                          show={firstCheckedLineParam === p[0]}
                          chartWidth={chartWidth}
                          chartHeight={chartHeight}
                          xAxisOffset={axis0Left}
                        />
                      }
                    />
                  }
                />
              )
          )}
          {axis.map((axis, axisIndex) => (
            <VictoryAxis
              key={axisIndex}
              dependentAxis
              noLabel={axis.label}
              orientation={axis.orientation}
              offsetX={axis.offsetX}
              tickFormat={(t) => t}
              style={{
                axisLabel: { fontSize: 9, padding: 20 },
                tickLabels: { fontSize: 8, padding: 5 },
              }}
            />
          ))}

          <VictoryAxis
            tickFormat={xAxisFormat}
            style={{ tickLabels: { fontSize: 10 } }}
          />
        </VictoryChart>

        <VictoryChart
          className={myStyles.charts}
          padding={{
            top: layoutChart2.top,
            left: 50,
            right: 50,
            bottom: layoutChart2.bottom,
          }}
          width={600}
          height={layoutChart2.bottom + layoutChart2.height + layoutChart2.top}
          scale={{ x: scaleUtc() }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryBrushContainer
              brushDimension='x'
              brushDomain={zoom.zoomDomain}
              onBrushDomainChange={handleZoomBrush}
              className={myStyles.charts}
            />
          }
        >
          <VictoryAxis
            offsetY={layoutChart2.bottom}
            tickFormat={xAxisFormat}
            style={{ tickLabels: { fontSize: 10 } }}
          />
          {params.map(
            (p, pIndex) =>
              checked[p[0]] &&
              isLineParam(p[0]) && (
                <VictoryLine
                  key={p[0]}
                  interpolation={defaultInterpolationVictory}
                  style={{
                    data: {
                      stroke: defaultColorOfParam(p[0]),
                      strokeWidth: 1,
                    },
                  }}
                  data={values}
                  name={p[0]}
                  x='timeAsDate'
                  y={(d) => lineValue(d, pIndex, p[0])}
                />
              )
          )}
        </VictoryChart>
      </div>
      <FormGroup>
        {params.map(
          (p, pIndex) =>
            isLineParam(p[0]) && (
              <>
                <FormControlLabel
                  key={p[0]}
                  label={p[1]}
                  control={
                    <Checkbox
                      checked={checked[p[0]]}
                      onChange={() => handleCheckChange(p[0])}
                    />
                  }
                />
                  <svg key={pIndex} viewBox='0 0 400 20' width='400px' height='20px'>
                    <path
                      d='M30,1 L400,1'
                      stroke={defaultColorOfParam(p[0])}
                      strokeWidth='4'
                      strokeDasharray={defaultDashArrayOfParam(p[0])}
                    />
                  </svg>
              </>
            )
        )}
        {/* <p className={myStyles.legendHint}>{t("legend_symbol_hint")}</p> */}
      </FormGroup>
    </div>
  )
}

export default SpotWeatherDataChartMeteo
