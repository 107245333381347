import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"

import { Box, Typography, CardMedia, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useAccessContext } from '../../modules/AccessContext'
import { setSingleImage, setAllImages, setText } from '../../modules/requestHelper'
import PostGraphileApi from '../../modules/PostGraphileApi'
import HorizontalImageBar from '../HorizontalImageBar/HorizontalImageBar'
import ErrorSnack from '../ErrorSnack/ErrorSnack'

import useStyles from './styles'

const PegelModule = ( { styles, update } ) => {
    const { t } = useTranslation()
    const { accessContext, setAccessContext } = useAccessContext()

    //const [pegelImages, setPegelImages] = useState([]);
    const [msg, setMessage] = useState('');
    const [pegelImage, setPegelImage] = useState({ src: "images/logo.png"})
    const msgPrefix = t("error_data_api_request")
    const [snack, setSnack] = useState({ open: false });
    const handleCloseSnack = () => {
        setSnack((state) => ({ ...state, open: false}))
    }
    const data = [
      { location: "Kieler Bucht", max: "", min: "", trend: "" },
      { location: "Lübecker Bucht", max: "", min: "", trend: "" },
      { location: "westlich Rügens", max: "", min: "-", trend: "" },
      { location: "östlich Rügens", max: "", min: "", trend: "" },
      { location: "Kleines Haff", max: "+", min: "+", trend: "" },
    ];
    // const rawData = accessContext.payload.pegelBSH.data.wasserstands.edges.map(edge => ({
    //   location: getLocationNameById(edge.node.locationId), // Funktion, die den Namen aus der ID ableitet
    //   max: `+${parseInt(edge.node.maxLevel, 10) - 500}`, // Beispielberechnung
    //   min: `${parseInt(edge.node.minLevel, 10) - 500}`, // Beispielberechnung
    //   trend: edge.node.trend === '-' ? '→' : edge.node.trend // Trend zu Symbol
    // }));



    const transformData = (edges) => {
      // Mapping der locationId auf die Regionennamen
      const locationMap = {
        1: "Kieler Bucht",
        2: "Lübecker Bucht",
        3: "westlich Rügens",
        4: "östlich Rügens",
        5: "Kleines Haff"
      };
    
      const intervals = ["Bis Heute Abend", "Heute in der Nacht", "Morgen im Tagesverlauf"];
    

      const transformed = intervals.map((interval, index) => {

        const start = index * 5;
        const end = start + 5;
    

        const intervalData = edges.slice(start, end).map((edge) => {
          const { locationId, maxLevel, minLevel, trend } = edge.node;
    
          return {
            location: locationMap[locationId], 
            max: `+${parseInt(maxLevel, 10) - 500}`, 
            min: `${parseInt(minLevel, 10) - 500}`, 
            trend: trend === "-" ? "→" : trend 
          };
        });
    
        return {
          interval, 
          data: intervalData 
        };
      });
    
      return transformed;
    };
    
    
    const formattedData = transformData(accessContext.payload.pegelBSH.edges);
    
    
    //console.log(formattedData);


    useEffect(() => {   // init component with images

      setSingleImage(accessContext, accessContext.configuration?.pegel?.images, 0, setPegelImage)
      //setAllImages(accessContext, accessContext.configuration?.pegel?.images, setPegelImages, errorHandler)
    }, [accessContext, msgPrefix, update]);  

    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, 
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        width: "100%",
      }}
    >
            <Box
        component="img"
        src={pegelImage.src}
        alt="Wasserstand"
        sx={{
          maxWidth: "1000px", 
          height: "auto", 
          maxHeight: "800px", 
          flex: { xs: "2 1 66%", md: "2 1 66%", xl: "2 1 66%" }, 
        }}
      />
                <TableContainer component={Paper}
                        sx={{
                          flex: { xs: "1 1 33%", md: "1 1 33%", xl: "0 0 auto"}, 
                          width: { xs: "10%", md: "30%" }, 
                        }}>
      <Table>
        {/* Tabellenkopf */}
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {data.map((row, index) => (
              <TableCell key={index} align="center">{row.location}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Tabellenkörper */}
        <TableBody>
  {formattedData.map((section) => (
    <React.Fragment key={section.interval}>
      <TableRow>
        <TableCell>{section.interval}</TableCell>
        {section.data.map((entry, index) => (
          <TableCell key={index} align="center">
            {entry.max} {entry.trend} <br />
            {entry.min}
          </TableCell>
        ))}
      </TableRow>
    </React.Fragment>
  ))}
</TableBody>
      </Table>
    </TableContainer>
    </Box>
    );
}

const PegelModuleTeaser = ({update}) => {
  const myStyles = useStyles()
  const { accessContext, setAccessContext } = useAccessContext()
  const [pegelImage, setPegelImage] = useState({ src: "images/logo.png"})

  useEffect(() => {   // init teaser with first image
    setSingleImage(accessContext, accessContext.configuration?.pegel?.images, 0, setPegelImage)
  }, [accessContext, update])

  return (
    <div>
        { pegelImage && (
            <CardMedia
              className={myStyles.dashboardCardMedia}
              component="img"
              image={pegelImage.src}
            />
        ) }
    </div>
  )
}

export { PegelModule, PegelModuleTeaser }
