import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material"
  import { useTranslation } from "react-i18next"
  import {
    getColumnHeader,
    getFormattedValue,
    isWWParam,
  } from "../../modules/layout"
  import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
  import useStyles from "./styles"
  import React from 'react';
  import { useAccessContext, setAccessContext } from '../../modules/AccessContext'
  const SpotWeatherDataTableMeteoTeaser = ({
    values = [],
    params = [],
    warnings = [],
    colors = [],
    checked = [],
    onCheckChange = () => {},
  }) => {
    const myStyles = useStyles()
    const { t } = useTranslation()
    const { accessContext, setAccessContext } = useAccessContext()
       // TODO configure parameter
    const paramsFilter = []
    let laenge = params.length

    //check limits




//Beim Aktualisieren wird diese Komponente Zwei Mal gerendert. Dabei sind kurzfristig fehlerhafte
//Werte in der Teaser Tabelle zu sehen. Das wird mit dem Deep Clone von params umgangen.
 let paramsCut = JSON.parse(JSON.stringify(params))

let warnLimits = []
 params.forEach((elem, index) =>{
  // pattern = r'limit:(\d+(\.\d+)?)'
  let result = warnings[index][0].match(/limit:(\d+(\.\d+)?)/)
  if (result !== null) {
    //console.log(elem[0])
    let newEntry = [ elem[0], index +1, {limit: result[1], prelimit: 2, step: 1}]
    warnLimits.push(newEntry)
   // setLimits({ ...limits, [elem[0]]: {limit: result[1], prelimit: 2, step: 1}})
    //console.log(result[1])
  }
  
//console.log(result)
//console.log(elem)
})

for (let i =0; i<warnLimits.length; i++) {
  paramsFilter.push(warnLimits[i][0])
}

for (let i=laenge-1; i > -1;i--) {
  if (paramsFilter.includes(params[i][0])){
   paramsCut[i][3] = i
  } else {
    paramsCut.splice(i,1)    
   }  
  }

  let zahl = paramsCut.length
  if (zahl > 2) {
    paramsCut.splice(2)
  }

  let warnTimeStep =1
  let warnParam = []

for (let i=0; i<warnLimits.length;i++) {
    for (let j=0; j < values.length; j++) {
      if (values[j]['param'+warnLimits[i][1]][0] >= warnLimits[i][2].limit ) {
        //console.log(values[j])

        warnTimeStep = j
        warnParam.push(warnLimits[i][0])
        break
      }
    }
}
if (warnTimeStep+5 > values.length) {
  warnTimeStep -= 4
}
values.splice(0,warnTimeStep)
values.length = 5


  if (accessContext.payload.access.includes("local_time")) {
    for (let i=0;i<values.length;i++) {
      values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
    }
    }
    return (
      
      <Table stickyHeader>
        <TableHead>
          <TableRow key={"-1"} className={myStyles.tableRow}>
            <TableCell
              key={"-1"}
              className={myStyles.smallTableHeaderCell}
              align='center'
            >
              <div className={myStyles.headerCellTeaser}>
              {(accessContext.payload.access.includes("local_time")) ?
                  t("table_header_time_lt")
                  :
                  t("table_header_time_utc")}
              </div>
            </TableCell>
            {paramsCut.map((p, index) => (     
              
              <TableCell
                key={paramsCut[index][3]}
                className={myStyles.smallTableHeaderCell}
                align='center'
                sx={{ 
                  ...(warnParam.includes(p[0]) && { color:  'red', fontWeight: 'bold' })
                 }}
              >
                <div className={myStyles.headerCellTeaser}>
                  {getColumnHeader(p[0], p[2], t)}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row, rowIndex) => (            
            <TableRow key={rowIndex} hover className={myStyles.tableRow} >
              <TableCell
                key={"-1"}
                className={myStyles.smallTableCell}
                align='center'
              >
                {row.timeFormatted}
              </TableCell>
              {paramsCut.map((p, pIndex) => (
                
                <TableCell
                  key={paramsCut[pIndex][3]}
                  className={myStyles.tableCellBodyStyled}
                  align='center'
                  innervalue={getFormattedValue(row, p, paramsCut[pIndex][3], t)}
                >
                  {isWWParam(p[0]) === false &&
                    getFormattedValue(row, p, paramsCut[pIndex][3], t)}
                  {isWWParam(p[0]) === true && (
                    <WeatherIconComponent row={row} pIndex={paramsCut[pIndex][3]} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          )
          )}
        </TableBody>
      </Table>
    )

  }

  export { SpotWeatherDataTableMeteoTeaser }
  