import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {

        // App Bar Elemente:

        toolBar: {
            padding: theme.spacing(1, 2)
        },
        toolBarButton: {
            marginRight: 10
        },
        toolBarIcon: {
            fontSize: 30
        },
        logo: {
            maxWidth: 75,
            marginRight: 20
        },
        toolbarTitle: {
            color: theme.palette.primary.dark,
        },
        spacer1: {
            flex: 10
        },
        spacer2: {
            flex: 1
        },
        filter: {
            backgroundColor: theme.palette.secondary.contrastText,
            margin: theme.spacing(0, 2),
            padding: theme.spacing(1, 1)
        },
        languageControl: {
            margin: theme.spacing(0, 2),
        },
        languageLabel: {
            minWidth: 100,
            //alignContent: "center",
            position: "absolut",
            top: 10,
            left: -15,
            color: theme.palette.secondary.contrastText,
            fontSize: 16
        },
        language: { 
            color: theme.palette.secondary.contrastText,
            fontSize: 16
        },
        comment: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.dark,
            marginRight: 10
        },
        bottomNavigation: {
            minHeight: 75,
            padding: theme.spacing(2),
            color: "inherit",
            textAlign: "center"
        },
        bottomNavButton: {
            padding: theme.spacing(2, 2),
        },

        // Module allgemein:

        moduleHeader: {
            margin: theme.spacing(2, 0, 3),
            color: theme.palette.primary.dark
        },
        moduleText: {
            color: theme.palette.primary.dark
        },

        // Module speziell:
        
        dashboardCard: {
            height: 320,
        },

        dashboardCardMedia2: {
            margin: theme.spacing(2, 0, 0),
            height: "100px",
            width: "auto"
        },
          
        dashboardMapContainerStyle: {
            width: "80%",
            height: "160px"
        },

        mapContainerStyle: {
            width: "100%",
            height: "100vh" // vh!!!
        },

        synopsisCardStyles: {
            maxWidth: 800,
        },

        timeLine: {
            maxWidth: 800,
            maxHeight: 600,
        },

        heatmap: {
            maxWidth: 800,
            maxHeight: 600,
        },

        spotWeatherTableGridItem: {
            margin: theme.spacing(0, 10, 0, 0),
        },

        spotWeatherTable: {
            maxHeight: "600px"
        },
        
        spotWeatherTimeLineGridItem: {
            maxWidth: 700,
            maxHeight: 600,
        },

        tickLabels: {
            fontSize: 10
        },

        spotWeatherHeatmap: {
            margin: theme.spacing(2, 0, 0),
            height: "600px",
        },

        // Gesamt Layout:

        appBar: {
            backgroundColor: theme.palette.secondary.main,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        appBarShift: {
            width: `calc(100% - ${theme.mixins.drawer.width}px)`,
            marginLeft: theme.mixins.drawer.width,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        },
        content: {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            minHeight: 640,
        },
        contentShift: {
            width: `calc(100% - ${theme.mixins.drawer.width}px)`,
            marginLeft: theme.mixins.drawer.width,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            })
        },
        module: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
            margin: theme.spacing(2, 1)
        },
        moduleWithoutMargin: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0.5, 0),
        },
        footer: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.light,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        footerShift: {
            width: `calc(100% - ${theme.mixins.drawer.width}px)`,
            marginLeft: theme.mixins.drawer.width,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }
    })
})

export default useStyles