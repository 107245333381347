import JSON5 from "json5" // For handling JSON with comments and unquoted keys
import { jwtDecode } from "jwt-decode" // To decode JWT tokens

const PostGraphileApi = {
  /**
   * Check if the token is expired.
   * @param {string} token
   * @return {boolean}
   */
  isTokenExpired(token) {
    if (!token) return true
    const decoded = jwtDecode(token)
    const currentTime = Date.now() / 1000
    return decoded.exp < currentTime
  },

  /**
   * Check and refresh the token if necessary.
   * @param {object} accessContext
   * @param {function} setAccessContext
   * @return {Promise<string|null>} The valid access token or null if unable to refresh.
   */
  async checkAndRefreshToken(accessContext, setAccessContext) {
    if (this.isTokenExpired(accessContext.token)) {
      console.log("Access Token expired.")
      try {
        const response = await fetch(`${accessContext.environment.auth.url}/auth/token`, {
          method: "POST",
          credentials: "include", // Include cookies in the request
          headers: {
            "Content-Type": "application/json",
          },
        })
        if (response.ok) {
          const data = await response.json()
          setAccessContext((prevContext) => ({
            ...prevContext,
            token: data.accessToken,
          }))
          console.log("Access Token refreshed.")
          return data.accessToken
        } else {
          console.error("Failed to refresh token: ", response.statusText)
          return null
        }
      } catch (error) {
        console.error("Error refreshing token:", error)
        return null
      }
    }
    return accessContext.token
  },

  /**
   * Fetch request with automatic token refresh.
   * @param {string} fetchUrl
   * @param {string} graphqlQuery
   * @param {object} accessContext
   * @param {function} setAccessContext
   * @param {string} errorText
   * @return {Promise<any>}
   */
  async fetchRequestWithTokenRefresh(fetchUrl, graphqlQuery, accessContext, setAccessContext, errorText) {
    const accessToken = await this.checkAndRefreshToken(accessContext, setAccessContext)
    if (!accessToken) {
      throw new Error("Failed to get access token")
    }
    const requestData = this.httpRequestData(graphqlQuery, accessToken)
    return this.fetchRequest(fetchUrl, requestData, errorText)
  },

  /**
   * Fetch request with automatic token refresh (generic version).
   * @param {string} fetchUrl
   * @param {RequestInit} requestOptions
   * @param {object} accessContext
   * @param {function} setAccessContext
   * @param {string} errorText
   * @return {Promise<any>}
   */
  async fetchRequestWithTokenRefreshGeneric(fetchUrl, requestOptions, accessContext, setAccessContext, errorText) {
    const accessToken = await this.checkAndRefreshToken(accessContext, setAccessContext)
    if (!accessToken) {
      throw new Error("Failed to get access token")
    }
    requestOptions.headers = requestOptions.headers || {}
    requestOptions.headers["Authorization"] = "Bearer " + accessToken
    return this.fetchRequest(fetchUrl, requestOptions, errorText)
  },

  /**
   * Original fetchRequest function.
   * @param {RequestInfo | URL} fetchUrl
   * @param {RequestInit | undefined} request
   * @param {string} errorText
   * @return {Promise<any>}
   */
  async fetchRequest(fetchUrl, request, errorText) {
    return fetch(fetchUrl, request)
      .then((response) => {
        if (response.ok) {
          return response
        }
        throw Error(errorText + response.statusText)
      })
      .then((res) => {
        const contentType = res.headers.get("content-type")
        if (contentType && contentType.includes("application/json")) {
          return res.json()
        } else {
          return res.text()
        }
      })
  },

  /**
   * Create PostGraphile Request Header.
   * @param {string} graphqlQuery - GraphQL query string
   * @param {string | undefined} accessToken - Access token for authorization
   * @return {object} - HTTP request data
   */
  httpRequestData(graphqlQuery, accessToken = undefined) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Encoding": "gzip, deflate",
    }
    if (accessToken !== undefined) {
      headers["Authorization"] = "Bearer " + accessToken
    }
    return {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ query: graphqlQuery }),
    }
  },

  /**
   * Build a nodes query for GraphQL.
   * @param {string} graphqlFunction - The GraphQL function or query name
   * @param {string} graphqlDbFields - The database fields to select
   * @param {string} [graphqlDbJoinFields=""] - Optional additional fields from joined tables
   * @param {object} [graphqlCondition={}] - Conditions for the query
   * @return {string} - The constructed GraphQL query string
   */
  nodesQuery(graphqlFunction, graphqlDbFields, graphqlDbJoinFields = "", graphqlCondition = {}) {
    // console.log("JSON.stringify(graphqlCondition)="+JSON5.stringify(graphqlCondition));
    return `{ ${graphqlFunction}(condition: ${JSON5.stringify(
      graphqlCondition
    )}) { nodes {${graphqlDbFields}${graphqlDbJoinFields} }}}`
  },

  // Example: Components for complete PostGraphile query strings in, e.g., CustomerTable.js:
  customerPlusInfos: {
    nodesQuery: {
      function: "customers", // Corresponds to the DB table 'customer'
      dbFields:
        "id,type,firstName,lastName,organisationName,username,pin,commentary,sapId,webshopCustomerNumber,rdsId,protocolText,", // All DB fields of the 'customer' table
      dbJoinFields: "", // Optional additional fields from joins, solved with DB views (customer join ...), see DB view 'customer_plus_info'
      condition: {}, // Simple filter, see above
    },
  },

  stateKey: "data",
}

export default PostGraphileApi
