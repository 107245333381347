import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"

import { Typography, CardMedia, Grid } from '@mui/material';

import { useAccessContext } from '../../modules/AccessContext'
import { setSingleImage, setAllImages, setText } from '../../modules/requestHelper'

import HorizontalImageBar from '../HorizontalImageBar/HorizontalImageBar'
import ErrorSnack from '../ErrorSnack/ErrorSnack'

import useStyles from './styles'

const SynopsisModule = ( { styles, playerInterval, update } ) => {
    const { t } = useTranslation()
    const { accessContext, setAccessContext } = useAccessContext()

    console.log(accessContext)
    const [synopsisText, setSynopsisText] = useState('');
    const [warning, setWarning] = useState('');
    const [comment, setComment] = useState('');
    const [synopsisImages, setSynopsisImages] = useState([]);
    const [msg, setMessage] = useState('');

    //const msgPrefix = t("error_data_api_request")
    const [snack, setSnack] = useState({ open: false });
    const handleCloseSnack = () => {
        setSnack((state) => ({ ...state, open: false}))
    }
let commentVisible = true
    useEffect(() => {   // init component with texts
      let errorHandler = (result) => {
        setMessage("test1" + result.toString())
        setSnack((state) => ({ ...state, open: true}))
      }
      setText(accessContext, t, accessContext.configuration?.synopsis?.pathSynopsisText, setSynopsisText, errorHandler)
      setText(accessContext, t, accessContext.configuration?.meteoWarning?.path, setWarning, errorHandler)
      setText(accessContext, t, accessContext.configuration?.meteoComment?.path, setComment, errorHandler)
      // if (comment === undefined){
      //   commentVisible = false
      // } else {
      //   commentVisible = true
      // }
    }, [accessContext, t, update])

    
    
    useEffect(() => {   // init component with images
      let errorHandler = (result) => {
        console.log(result.toString())
        //setMessage("test2" + result.toString())
        //setSnack((state) => ({ ...state, open: true}))
      }
      setAllImages(accessContext, accessContext.configuration?.synopsis?.images, setSynopsisImages, errorHandler)
    }, [accessContext, update]);  

    return (
      <div className={styles.mainComponent}>
          {/* <ErrorSnack msg={msg} snackState={snack} onClose={handleCloseSnack} ></ErrorSnack> */}

            <Grid container justify="center" alignItems="flex-start" spacing={1}>
              <Grid item xs={6}>
                <HorizontalImageBar images={synopsisImages} cardStyles={styles.synopsisCardStyles} playerInterval={playerInterval}></HorizontalImageBar>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={styles.moduleHeader} align="left">{ t("synopsis_text_title") }</Typography>
                    <Typography variant="body2" className={styles.moduleText} align="left" paragraph>
                      { synopsisText }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={styles.moduleHeader} align="left">{ t("synopsis_warning_title") }</Typography>
                    <Typography variant="body2" className={styles.moduleText} align="left" paragraph>
                      { warning }
                    </Typography>
                  </Grid>
                  { (commentVisible) ?
                    <Grid item xs={12}>
                    <Typography variant="h5" className={styles.moduleHeader} align="left">{ t("synopsis_comment_title") }</Typography>
                      <Typography variant="body2" className={styles.moduleText} align="left" paragraph>
                       { comment }
                      </Typography>
                    </Grid>
                  :
                    ""
                   }
                </Grid>
              </Grid>
            </Grid>
      </div>
    );
}

const SynopsisModuleTeaser = ({update}) => {
  const myStyles = useStyles()
  const { accessContext, setAccessContext } = useAccessContext()
  const [synopsisImage, setSynopsisImage] = useState({ src: "images/logo.png"})

  useEffect(() => {   // init teaser with first image
    setSingleImage(accessContext, accessContext.configuration?.synopsis?.images, 0, setSynopsisImage)
  }, [accessContext, update])

  return (
    <div>
        { synopsisImage && (
            <CardMedia
              className={myStyles.dashboardCardMedia}
              component="img"
              image={synopsisImage.src}
            />
        ) }
    </div>
  )
}

export { SynopsisModule, SynopsisModuleTeaser }
