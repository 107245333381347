import PostGraphileApi from './PostGraphileApi'
import { METEO_CONSTANTS } from './constants'
//let axios = require('axios')
import axios from 'axios'

const dataApiDefaultTimeout = 3000

let getDataApi = async function(accessContext) {
    let axiosConfig = {}
    axiosConfig.baseURL = accessContext.environment.data_api.api
    axiosConfig.headers = {
        "Authorization": 'Bearer ' + accessContext.token
    }
    axiosConfig.timeout = dataApiDefaultTimeout

    if (accessContext.environment.data_api.timeout) {
      axiosConfig.timeout = accessContext.environment.data_api.timeout
    }

    return axios.create(axiosConfig)
}
  
async function getData (path, accessContext) {
    let api = await getDataApi(accessContext)

    return api.get(path)
        .then(result => {
            return result
        }).catch(err => {
            return err
        })
}

function setSingleImage(accessContext, images, index, setter, errorHandler) {
    if (images && images.length > index) {
      let image = images[index]
      getData(image.path, accessContext)
      .then(result => {
        if (result.status === 200) {
          if (result.data) {
            let contentType = result.headers["content-type"]
            if (contentType === 'application/json') {
              setter({ ...image, src: 'data:' + result.data.contentType + ';base64,' + result.data.base64 })
            } else {
              setter({ ...image, src: 'data:' + result.headers["content-type"] + ';base64,' + result.data })
            }
          }
        } else {
            if (errorHandler) {
              errorHandler(result)
            }
        }
      })
      .catch(e => {
        console.log(e)
      })
    }
}

function setImage(accessContext, image, setter, errorHandler) {
  let i = new Image();
  if (image) {
    getData(image.path, accessContext)
    .then(result => {
      if (result.status === 200) {
        if (result.data) {
          let contentType = result.headers["content-type"]
          if (contentType === 'application/json') {
            setter({ ...image, src: 'data:' + result.data.contentType + ';base64,' + result.data.base64 })
          } else {
            setter({ ...image, src: 'data:' + result.headers["content-type"] + ';base64,' + result.data })
          }
       //   console.log(result)
          
          i.src = 'data:' + result.headers["content-type"] + ';base64,' + result.data
   //       console.log(i.width)
        }
      } else {
          if (errorHandler) {
            errorHandler(result)
          }
      }
    })
    .catch(e => {
      console.log(e)
    })
  }
}

function setAllImages(accessContext, images, setter, errorHandler) {
    if (images && images.length > 0) {
        let promises = []
        images.forEach((image, index) => {
          promises.push(
            getData(image.path, accessContext)
            .then(result => {
              if (result.status === 200) {
                if (result.data) {
                  let contentType = result.headers["content-type"]
                  if (contentType === 'application/json') {
                    let img = { ...images[index], src: 'data:' + result.data.contentType + ';base64,' + result.data.base64 }
                    return img
                  } else {
                    let img = { ...images[index], src: 'data:' + result.headers["content-type"] + ';base64,' + result.data }
                    return img
                  }
                } else {
                  return null
                }
              } else {
                if (errorHandler) {
                  errorHandler(result)
                }
                return null
              }
            })
            .catch(e => {
              console.log(e)
            })
          )
        })

        Promise.all(promises)
        .then(result => {
          let newArray = []         // nur vorhandene images
          result.forEach(img => {
            if (img !== null) {
              newArray.push(img)
            }
          })
          setter(newArray)
        })
    }
}

function setText(accessContext, t, txt, setter, errorHandler) {
    if (txt) {
      getData(txt + t('api_lang_suffix'), accessContext)
      .then(result => {
        if (result.status === 200) {
          setter(result.data.text)
        } else {
          if (errorHandler) {
            errorHandler(result)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
    }
}

function setForecast(accessContext, forecasts, forecastIndex, t, setter, errorHandler) {
    if (forecasts && forecastIndex !== undefined && forecasts.length > forecastIndex) {
        let f = forecasts[forecastIndex]
        getData(f.path, accessContext)
        .then(result => {
          if (result.status === 200) {
            if (result.data) {
              result.data.forEach(row => {
                row["timeAsDate"] = new Date(parseInt(row.time))
                row["timeFormatted"] = t("format_datetime", {aDate: row["timeAsDate"]})
              })
              setter( { ...f, data: result.data })
            }
          } else {
              if (errorHandler) {
                errorHandler(result)
              }
              console.log(result)
          }
        })
        .catch(e => {
          console.log(e)
        })
      }
}

// meteo db

function setSpotWeatherOld(accessContext, spotweatherRequests, requestIndex, nrParams, locationIndex, t, setter, errorHandler) {
  let location = spotweatherRequests[requestIndex].spotweatherLocations.nodes[locationIndex]
  let locationId = location.id
  if (locationId) {
    let params = ""
    for (let i = 1 ; i <= nrParams; i++) params = params + ("param" + i + ", ")
    const query="{ spotweathers(condition: {spotweatherLocationId:  " + locationId + " }) { nodes { id, " + params + "type, forecastInitTime, forecastTime } } }"
    PostGraphileApi.fetchRequest(accessContext.environment.meteo_api.url, PostGraphileApi.httpRequestData(query, accessContext.token), 'spot weather, location id: ' + locationId)
    .then(result => {
        if (result?.data?.spotweathers.nodes.length >= 0) {
          let initTimes = [...new Set(result?.data?.spotweathers.nodes.map(w => w.forecastInitTime))]

          // TODO nur latest forecast, läßt sich das schon auf dem Server filtern?
          let latestInitTime = initTimes.sort()[initTimes.length - 1]
          let latestWeather = result?.data?.spotweathers.nodes.filter(w => w.forecastInitTime === latestInitTime)

          let headerText = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamDescType)[0]
          let params = headerText ? [...Array(nrParams).keys()].map(n => headerText["param" + (n + 1)]) : []
          
          let headerWarnColors = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamColorType)[0]
          let colors = headerWarnColors ? [...Array(nrParams).keys()].map(n => headerWarnColors["param" + (n + 1)]) : []

          let headerWarnExpression = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamWarnExprType)[0]
          let warnings = headerWarnExpression ? [...Array(nrParams).keys()].map(n => headerWarnExpression["param" + (n + 1)]) : []

          let values = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamValueType)
          values.sort((v1, v2) => v1.forecastTime < v2.forecastTime ? -1 : (v1.forecastTime > v2.forecastTime ? 1 : 0)) 
          values.forEach(v => {
            v["timeAsDate"] = new Date(v.forecastTime)
            v["timeFormatted"] = t("format_datetimeTable", {aDate: v["timeAsDate"]})
          })

          setter( { ...location, params: params, warnings: warnings, colors: colors, values: values })
        } else {
          if (errorHandler) {
            errorHandler(result)
          }
        }
    }).catch(e => {
      if (errorHandler) {
        errorHandler(e)
      }
    })
  }
}

function setSpotWeather(accessContext, setAccessContext, spotweatherRequests, requestIndex, nrParams, locationIndex, t, setter, errorHandler) {
  console.log(accessContext)
  let location = spotweatherRequests[requestIndex].spotweatherLocations.nodes[locationIndex]
  let locationId = location.id
  if (locationId) {
    let params = ""
    for (let i = 1 ; i <= nrParams; i++) params = params + ("param" + i + ", ")
    const query="{ spotweathers(condition: {spotweatherLocationId:  " + locationId + " }) { nodes { id, " + params + "type, forecastInitTime, forecastTime } } }"
    PostGraphileApi.fetchRequestWithTokenRefresh(accessContext.environment.meteo_api.url,
      query,
      accessContext,
      setAccessContext,
      'spot weather, location id: ' + locationId)
    .then(result => {
        if (result?.data?.spotweathers.nodes.length >= 0) {
          let initTimes = [...new Set(result?.data?.spotweathers.nodes.map(w => w.forecastInitTime))]

          // TODO nur latest forecast, läßt sich das schon auf dem Server filtern?
          let latestInitTime = initTimes.sort()[initTimes.length - 1]
          let latestWeather = result?.data?.spotweathers.nodes.filter(w => w.forecastInitTime === latestInitTime)

          let headerText = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamDescType)[0]
          let params = headerText ? [...Array(nrParams).keys()].map(n => headerText["param" + (n + 1)]) : []
          
          let headerWarnColors = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamColorType)[0]
          let colors = headerWarnColors ? [...Array(nrParams).keys()].map(n => headerWarnColors["param" + (n + 1)]) : []

          let headerWarnExpression = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamWarnExprType)[0]
          let warnings = headerWarnExpression ? [...Array(nrParams).keys()].map(n => headerWarnExpression["param" + (n + 1)]) : []

          let values = latestWeather.filter(w => w.type === METEO_CONSTANTS.spotweatherParamValueType)
          values.sort((v1, v2) => v1.forecastTime < v2.forecastTime ? -1 : (v1.forecastTime > v2.forecastTime ? 1 : 0)) 
          values.forEach(v => {
            v["timeAsDate"] = new Date(v.forecastTime)
            v["timeFormatted"] = t("format_datetimeTable", {aDate: v["timeAsDate"]})
          })

          setter( { ...location, params: params, warnings: warnings, colors: colors, values: values })
        } else {
          if (errorHandler) {
            errorHandler(result)
          }
        }
    }).catch(e => {
      if (errorHandler) {
        errorHandler(e)
      }
    })
  }
}

export { getData, setSingleImage, setAllImages, setImage, setText, setForecast, setSpotWeather, setSpotWeatherOld }
