import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material"
import { React, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  getColumnHeader,
  getFormattedValue,
  isWWParam,
} from "../../modules/layout"
import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
import useStyles from "./styles"
import styled from "styled-components"

import { useAccessContext, setAccessContext } from '../../modules/AccessContext'

const SpotWeatherDataTableMeteo = ({
  values = [],
  params = [],
  warnings = [],
  colors = [],
  checked = [],
  onCheckChange = () => {},
}) => {
  const myStyles = useStyles()
  const { t } = useTranslation()
  const { accessContext, setAccessContext } = useAccessContext()






  let [limits, setLimits] = useState({

    //FF: { limit: "5.9", prelimit: 5.9, step: 1 },
 
    //FFDIR: "",
    // PPPP: "",
    // T: "",
    // RH: "",
    // NCL: "",
    // CUR: "",
    // CURDIRTO: "",
     //"W-HSSW": { limit: "", prelimit: 0.7, step: 0.1 },
    // "W-DMEAN": "",
    // "W-PMEAN": "",
    // FFG_LOG_50_M: "",
    //FFG: { limit: "", prelimit: 5.9, step: 1 },
    // FF_LOG_50_M: "",
    // FFDIR_LOG_50_M: "",
    // VV: "",
    //"W-WSHMAX": { limit: "", prelimit: 0.7, step: 0.1 },
  })

  const parameterUnits = {
    FF: ["m/s", "km/h", "bft", "kn"],
    FFG: ["m/s", "km/h", "bft", "kn"],
    FFG_LOG_50_M: ["m/s", "km/h", "bft", "kn"],
    FF_LOG_50_M: ["m/s", "km/h", "bft", "kn"],
    FFDIR_LOG_50_M: ["°", "text"],
    FFDIR: ["°", "text"],
    PPPP: [" "],
    T: ["°C", "°K"],
    T0: ["°C", "°K"],
    WW: [" "],
    VV: ["km"],
    RH: ["%"],
    NCL: ["%"],
    CUR: ["m/s", "km/h", "kn"],
    CURDIRTO: ["°", "text"],
    "W-HSSW": ["m"],
    "W-DMEAN": ["°", "text"],
    "W-PMEAN": ["s"],
    "W-WSHMAX": ["m"],
    "W-PSEA": ["s"],
    "W-DSW": ["°", "text"],
    "W-PSW": ["s"],
    "W-HSW": ["m"],
    "W_HSSW": ["m"],
    "W_DMEAN": ["°", "text"],
    "W_PMEAN": ["s"],
    "W_WSHMAX": ["m"],
    "W_PSEA": ["s"],
    "W_DSW": ["°", "text"],
    "W_PSW": ["s"],
    "W_HSW": ["m"],
    SST: ["°C", "°K"],
    U: [" "],
    V: [" "],
    RRR1: ["mm"],
    RRR3: ["mm"],
    RRR6: ["mm"],
    RRR12: ["mm"],
    RRR24: ["mm"],
    SNOWF1: ["mm"],
    SNOWF6: ["mm"],
    SNOD: ["m"],
    "P-WEATHER": [" "],
    "P_WEATHER": [" "],
  }

  const [currentUnits, setCurrentUnits] = useState({
    FF: 'm/s',
    FFG: "m/s",
    FFG_LOG_50_M: "m/s",
    FF_LOG_50_M: "m/s",
    FFDIR_LOG_50_M: "°",
    FFDIR: "°",
    PPPP: " ",
    T: "°C",
    T0: "°C",
    WW: "icon",
    VV: "km",
    RH: "%",
    NCL: "%",
    CUR: "m/s",
    CURDIRTO: "°",
    "W-HSSW": "m",
    "W-DMEAN": "°",
    "W-PMEAN": "s",
    "W-WSHMAX": "m",
    "W-PSEA": "s",
    "W-DSW": "°",
    "W-PSW": "s",
    "W-HSW": "m",
    "W_HSSW": "m",
    "W_DMEAN": "°",
    "W_PMEAN": "°",
    "W_WSHMAX": "m",
    "W_PSEA": "s",
    "W_DSW": "°",
    "W_PSW": "s",
    "W_HSW": "m",
    SST: "°C",
    //U: " ",
    //V: " ",
    RRR1: "mm",
    RRR3: "mm",
    RRR6: "mm",
    RRR12: "mm",
    RRR24: "mm",
    SNOWF1: "mm",
    SNOWF6: "mm",
    SNOD: "m",
    "P-WEATHER": " ",
    "P_WEATHER": " ",
  });
//    setLimits({ ...limits, [name]: { ...limits[name], limit: value } })
// for (let i=0; i< warnings.length; i++) {
//   let abc = "abcdef"
//   limits = {...limits, abc: {limit: "10", prelimit: 2, step: 1}}
// }
const regexLimit = new RegExp('limit:(\d+(\.\d+)?)')
useEffect(() => {
  //Runs on the first render
  //And any time any dependency value changes
  // let value = 
  // ({ ...limits, [name]: { ...limits[name], limit: value } })
  //console.log(accessContext.payload.spotweatherRequests[0].spotweatherParameters.nodes[3].param2)
  //console.log(params)
  params.forEach((elem, index) =>{
    // pattern = r'limit:(\d+(\.\d+)?)'
    let result = warnings[index][0].match(/limit:(\d+(\.\d+)?)/)
    if (result !== null) {
      //console.log(elem[0])
      let newEntry = { [elem[0]]: {limit: result[1], prelimit: 2, step: 1}}
      setLimits(prevLimits => ({
        ...prevLimits,
        ...newEntry
      }))
     // setLimits({ ...limits, [elem[0]]: {limit: result[1], prelimit: 2, step: 1}})
      //console.log(result[1])
    }

    
  //console.log(result)
  //console.log(elem)
}

  )

 // setLimits( { ...limits, FF: {limit: "10", prelimit: 2, step: 1}})
}, [accessContext]);


  //console.log(warnings)
  //console.log(limits)


  let cloudsIndex
  if (params[params.length-1][0] === "NCL") {
    cloudsIndex = params.length
    params.pop()
  }
  
  if (accessContext.payload.access.includes("RRRone_ri")) {
    for (let i=48; i < values.length; i++) {
      values[i].param5 = [""]
    }
    for (let j=0; j < 48; j++) {
      values[j].param6 = [""]
    }
    for (let k=47; k < 56; k++) {
      values[k].param6 = [""]
      k++
    }
  }
  if (accessContext.payload.access.includes("woa")) {
  for (let i=0;i<values.length;i++) {
    values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
  }
  }
  // console.log(params)
  // console.log(values)
  // let newValues
  // newValues = JSON.parse(JSON.stringify(values));
//Parameter Value Einheiten Umrechnung
//currentUnits
  const [currentValues, setCurrentValues] = useState(JSON.parse(JSON.stringify(values)))
  // console.log(JSON.parse(JSON.stringify(values)))
  // console.log(currentValues)
const [dropdownVisible, setDropdownVisible] = useState(null);

const handleUnitClick = (parameter) => {
  if (accessContext.payload.access.includes("Calculate")) {
  
  setDropdownVisible(dropdownVisible === parameter ? null : parameter);
  }
};

const handleUnitSelect = (parameter, unit) => {
  if (accessContext.payload.access.includes("Calculate")) {
  setCurrentUnits({
    ...currentUnits,
    [parameter]: unit,
  });
//updateLimits(parameter, unit)


  setDropdownVisible(null); // Dropdown schließen


  updateColumnValues(parameter, unit)
}

};

useEffect(() => {
  if (accessContext.payload.access.includes("DefaultNOSW")) {
    setCurrentUnits(prevUnits => {
      const updatedUnits = { ...prevUnits };

      for (let i = 0; i < params.length; i++) {
          if (params[i][2] === "°") {
            updatedUnits[params[i][0]] = "text";
            updateColumnValues(params[i][0], "text")
          }
      }
      return updatedUnits;
    });
  }

}, [])
useEffect(() => {
  if (accessContext.payload.access.includes("DefaultBFT")) {
    setCurrentUnits(prevUnits => {
      const updatedUnits = { ...prevUnits };

      for (let i = 0; i < params.length; i++) {
        if (params[i][0] !== "CUR") {
          if (params[i][2] === "m/s") {
            updatedUnits[params[i][0]] = "bft";
            updateColumnValues(params[i][0], "bft")
          }
        }
      }
      return updatedUnits;
    });
  }
}, []);
useEffect(() => {
  if (accessContext.payload.access.includes("DefaultKMH")) {
    setCurrentUnits(prevUnits => {
      const updatedUnits = { ...prevUnits };

      for (let i = 0; i < params.length; i++) {
        if (params[i][0] !== "CUR") {
          if (params[i][2] === "m/s") {
            updatedUnits[params[i][0]] = "km/h";
            updateColumnValues(params[i][0], "km/h")
          }
        }
      }
      return updatedUnits;
    });
  }
}, []);

//Math.round(number * 10) / 10
// to do
// vorherige unit muss bekannt sein für die umrechnung oder limits muss mit kopie in m/s gearbeitet werden
const updateLimits = (parameter, unit, oldvalue) => {
  let paramNumber
  let paramCounter
  for (let i = 0; i < params.length; i++) {
    if (params[i][0] === parameter) {
      paramNumber = "param" + (i+1)
      paramCounter = i
    }
  }
  if (params[paramCounter][2] === 'm/s') {
        let updatedValue;
        if (unit === "m/s") {
          updatedValue = String(Number(oldvalue))
        } else if (unit === "km/h") {
          updatedValue = String(Math.round(Number(oldvalue) * 36) / 10)
        } else if (unit === "kn") {
          updatedValue = String(Math.round(Number(oldvalue) * 19.4384) / 10)
        } else if (unit === "bft") {
          let bftnumber
      switch (true) {
        case oldvalue < 0.3:
          bftnumber = "0";
        break;
        case oldvalue < 1.6:
          bftnumber = "1";
        break;
        case oldvalue < 3.4:
          bftnumber = "2";
        break;
        case oldvalue < 5.5:
          bftnumber = "3";
        break;
        case oldvalue < 8:
          bftnumber = "4";
        break;
        case oldvalue < 10.8:
          bftnumber = "5";
        break;
        case oldvalue < 13.9:
          bftnumber = "6";
        break;
        case oldvalue < 17.2:
          bftnumber = "7";
        break;
        case oldvalue < 20.8:
          bftnumber = "8";
        break;
        case oldvalue < 24.5:
          bftnumber = "9";
        break;
        case oldvalue < 28.5:
          bftnumber = "10";
        break;
        case oldvalue < 32.7:
          bftnumber = "11";
        break;
        case oldvalue > 32.6:
          bftnumber = "12";
      }
      updatedValue = String(bftnumber)
        }
        setLimits({ ...limits, [parameter]: { ...limits[parameter], limit: updatedValue } })
}
  
}


const updateColumnValues = (parameter, unit) => {
  let paramNumber
  let paramCounter
  for (let i = 0; i < params.length; i++) {
    if (params[i][0] === parameter) {
      paramNumber = "param" + (i+1)
      paramCounter = i
    }
  }
  

if (params[paramCounter][2] === 'm/s') {
    setCurrentValues(prevValues => {
      return prevValues.map((row, index) => {
        const tmpRowValue = values[index][paramNumber];
        let updatedValue;

        if (unit === "m/s") {
          updatedValue = String(Number(tmpRowValue[0]))
        } else if (unit === "km/h") {
          updatedValue = String(Math.round(Number(tmpRowValue[0]) * 36) / 10)
        } else if (unit === "kn") {
          updatedValue = String(Math.round(Number(tmpRowValue[0]) * 19.4384) / 10)
        } else if (unit === "bft") {
          let bftnumber
      switch (true) {
        case tmpRowValue[0] < 0.3:
          bftnumber = "0";
        break;
        case tmpRowValue[0] < 1.6:
          bftnumber = "1";
        break;
        case tmpRowValue[0] < 3.4:
          bftnumber = "2";
        break;
        case tmpRowValue[0] < 5.5:
          bftnumber = "3";
        break;
        case tmpRowValue[0] < 8:
          bftnumber = "4";
        break;
        case tmpRowValue[0] < 10.8:
          bftnumber = "5";
        break;
        case tmpRowValue[0] < 13.9:
          bftnumber = "6";
        break;
        case tmpRowValue[0] < 17.2:
          bftnumber = "7";
        break;
        case tmpRowValue[0] < 20.8:
          bftnumber = "8";
        break;
        case tmpRowValue[0] < 24.5:
          bftnumber = "9";
        break;
        case tmpRowValue[0] < 28.5:
          bftnumber = "10";
        break;
        case tmpRowValue[0] < 32.7:
          bftnumber = "11";
        break;
        case tmpRowValue[0] > 32.6:
          bftnumber = "12";
      }
      updatedValue = String(bftnumber)
        }
        return {
          ...row,
          [paramNumber]: [updatedValue],
        };
      });
    });
  
} else if (params[paramCounter][2] === '°') {
  setCurrentValues(prevValues => {
    return prevValues.map( (row, index) => {
      const tmpRowValue = values[index][paramNumber];
      let updatedValue;
   if (unit === '°') {
    updatedValue = String(Number(tmpRowValue[0]));
    }  else if (unit === 'text') {
      let noswText
      switch (true) {
        case tmpRowValue[0] < 23:
        noswText = "N";
        break;
        case tmpRowValue[0] < 68:
        noswText = "NO";
        break;
        case tmpRowValue[0] < 113:
        noswText = "O";
        break;
        case tmpRowValue[0] < 158:
        noswText = "SO";
        break;
        case tmpRowValue[0] < 203:
        noswText = "S";
        break;
        case tmpRowValue[0] < 248:
        noswText = "SW";
        break;
        case tmpRowValue[0] < 293:
        noswText = "W";
        break;
        case tmpRowValue[0] < 338:
        noswText = "NW";
        break;
        case tmpRowValue[0] < 361:
        noswText = "NW";

      }
      updatedValue = String(noswText)
  }
      return {
        ...row,
        [paramNumber]: [updatedValue],
      };
    });
  })


} else if (params[paramCounter][2] === '°C') {
  setCurrentValues(prevValues => {
    return prevValues.map((row, index) => {
      const tmpRowValue = values[index][paramNumber];
      let updatedValue;
  if (unit === '°C') {
    updatedValue = String(Number(tmpRowValue[0]));
    } else if (unit === '°K') {
    updatedValue = String(Number(tmpRowValue[0]) + 273)
    }
    return {
      ...row,
      [paramNumber]: [updatedValue],
    };
  });
})

}


};

const getnewColumnHeader = (param, unit) => {

  return t("table_header_param_" + param) + " (" + unit + ")"
}

let datumObjAktuell = new Date(currentValues[0].timeAsDate);
let aktuellesDatum = datumObjAktuell.toLocaleDateString()
//console.log(currentValues[0])
const isNewDay = (currentTimestamp, previousTimestamp) => {
  const currentDate = new Date(currentTimestamp).toDateString();
  const previousDate = new Date(previousTimestamp).toDateString();
  return currentDate !== previousDate;
};

//   console.log(values[0])
// console.log(values[0].timeAsDate)
// console.log(values[0].timeAsDate.toISOString().split('T')[0])
// console.log(values[0].timeAsDate.toLocaleDateString())
// console.log(values[0].timeAsDate.toLocaleTimeString().slice(0,5))
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow key={"-1"} className={myStyles.tableRow}>
          <TableCell
            key={"-1"}
            className={myStyles.smallTableHeaderCell}
            align='center'
          >
            <div className={myStyles.headerCell}>
              {(accessContext.payload.access.includes("local_time")) ?
                  t("table_header_time_lt")
                  :
                  t("table_header_time_utc")}
              
              {/* <div className={myStyles.checkBoxWrapper} /> */}
              <div className={myStyles.inputWrapper} />
            </div>
          </TableCell>
          {params.map((p, index) => (
            <TableCell
              key={index}
              className={myStyles.smallTableHeaderCell}
              align='center'
            >
                {/* {getColumnHeader(p[0], p[2], t)}
                
                {currentUnits[p[0]]} */}


                <div className={myStyles.headerCell} >
                  <div onClick={() => handleUnitClick(p[0])}  style={{
                  cursor:  "pointer", // Zeiger-Cursor nur bei onClick
                }}>
                      {accessContext.payload.access.includes("Calculate") ?
                      
                       getnewColumnHeader(p[0], currentUnits[p[0]])
                      :
                      getColumnHeader(p[0], p[2], t)}
                      
                        {dropdownVisible === p[0] && (
                        <div className={myStyles.dropdownMenu}>
                        {parameterUnits[p[0]].map((unit) => (
                          <div
                      key={unit}
                      className={myStyles.dropdownItem}
                          onClick={() => handleUnitSelect(p[0], unit)}
                        >
                      {unit}
                      </div>
                    ))}
                  </div>
                  )}
                  </div>
                <div className={myStyles.inputWrapper}>
                  {p[0] in limits && (
                    <>
                      
                      <Tooltip
                        title='Grenzwert für Warnrot'
                        placement='top'
                        arrow
                      >
                        <input
                          className={myStyles.limitInput}
                          type='number'
                          step={limits[p[0]].step}
                          name={p[0]}
                          onChange={handleChange}
                          value={limits[p[0]].limit}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>



      <TableBody>
        {currentValues.map((row, rowIndex) => {
              const datumObj = new Date(row.timeAsDate);
              const datum = datumObj.toLocaleDateString();
              
                    
              const isNeuesDatum = datum !== aktuellesDatum;
                if (isNeuesDatum) {
                aktuellesDatum = datum;
              }
              return (
          <TableRow key={rowIndex} hover className={myStyles.tableRow}>
            <TableCell
            sx={{ 
              ...(isNeuesDatum === true && { borderBottom:  '3px solid black'})
             }}
              key={"-1"}
              className={myStyles.smallTableCell}
              align='center'
            >
              {row.timeFormatted}
            </TableCell>
        
            {params.map((p, pIndex) => (
              <TableCellBodyStyled
                key={pIndex}
                // className={getTableCellStyleClasses(
                //   myStyles,
                //   row,
                //   p[0],
                //   pIndex,
                //   p[2],
                //   warnings,
                //   colors
                // )}
                sx={{ 
                  ...(isNeuesDatum === true && { borderBottom:  '3px solid black'})
                 }}
                align='center'
                innervalue={getFormattedValue(row, p, pIndex, t)}
                limit={limits[p[0]]?.limit}
                prelimit={limits[p[0]]?.prelimit}
              >
                {isWWParam(p[0]) === false &&
                  getFormattedValue(row, p, pIndex, t)}
                {isWWParam(p[0]) === true && (
                  <WeatherIconComponent row={row} pIndex={pIndex} cloudsIndex={cloudsIndex}/>
                )}
              </TableCellBodyStyled>
            ))}
          </TableRow>
)})}
      </TableBody>



    </Table>
  )
  function handleChange(event) {
    const { name, value } = event.target
    setLimits({ ...limits, [name]: { ...limits[name], limit: value } })
    //console.log(limits)
  }
}

const TableCellBodyStyled = styled(TableCell)`
  background-color: ${(props) => {
    if (props.limit === "") {
      return "inherit"
    } else if (Number(props.innervalue) >= Number(props.limit)) {
      return "#bb1e10"
    } else if (
      Number(props.innervalue) <
      Number(props.limit) - props.prelimit
    ) {
      return "#008351"
    } else if (
      Number(props.innervalue) >=
      Number(props.limit) - props.prelimit
    ) {
      return "#EFB700"
    } else {
      return "inherit"
    }
  }};
  color: ${(props) => {
    if (props.limit === "") {
      return "theme.palette.background.paper"
    } else if (Number(props.innervalue) >= Number(props.limit)) {
      return "white"
    } else if (
      Number(props.innervalue) <
      Number(props.limit) - props.prelimit
    ) {
      return "white"
    } else if (
      Number(props.innervalue) >=
      Number(props.limit) - props.prelimit
    ) {
      return "theme.palette.background.paper"
    } else {
      return "theme.palette.background.paper"
    }
  }};
`

export { SpotWeatherDataTableMeteo }
