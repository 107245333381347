import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from "react-i18next"

//import { Typography, CardMedia, Grid } from '@mui/material';

import { useAccessContext } from '../../modules/AccessContext'
import PostGraphileApi from '../../modules/PostGraphileApi'

import {Grid, TextField, Button, CardMedia} from '@mui/material';
import Typography from '@mui/material/Typography';
import { setSingleImage } from '../../modules/requestHelper'
// import markerIconPng from "leaflet/dist/images/marker-icon-2x.png"
import {Icon} from 'leaflet'

import useStyles from './styles'
import { FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
//import proj4 from 'proj4';
// import generatePDF from 'react-to-pdf';


const SingleSpotweather = ( { styles, playerInterval, update } ) => {
    const { t } = useTranslation()
    const { accessContext, setAccessContext } = useAccessContext()
    const [currentValues, setCurrentValues] = useState([])
    const tablePDF = useRef()
    const parameterUnits = {
      "FF": ["m/s", "km/h", "bft", "kn"],
      "FFG": ["m/s", "km/h", "bft", "kn"],
      FFG_LOG_50_M: ["m/s", "km/h", "bft", "kn"],
      FF_LOG_50_M: ["m/s", "km/h", "bft", "kn"],
      FFDIR_LOG_50_M: ["°", "text"],
      "FFDIR": ["°", "text"],
      PPPP: [" "],
      T: ["°C", "°K"],
      T0: ["°C", "°K"],
      WW: [" "],
      Sicht: ["km"],
      RH: ["%"],
      NCL: ["%"],
      CUR: ["m/s", "km/h", "bft", "kn"],
      CURDIRTO: ["°", "text"],
      "W-HSSW": ["m"],
      "Wellen Richtung": ["°", "text"],
      "W-PMEAN": ["s"],
      "W-WSHMAX": ["m"],
      "W-PSEA": ["s"],
      "W-DSW": ["°", "text"],
      "W-PSW": ["s"],
      "W-HSSW": ["m"],
      "W_HSSW": ["m"],
      "W-DMEAN": ["°", "text"],
      "W_PMEAN": ["s"],
      "W_WSHMAX": ["m"],
      "W_PSEA": ["s"],
      "W_DSW": ["°", "text"],
      "W_PSW": ["s"],
      "W_HSW": ["m"],
      SST: ["°C", "°K"],
      U: [" "],
      V: [" "],
      RRR1: ["mm"],
      RRR3: ["mm"],
      RRR6: ["mm"],
      RRR12: ["mm"],
      RRR24: ["mm"],
      SNOWF1: ["mm"],
      SNOWF6: ["mm"],
      SNOD: ["m"],
      "P-WEATHER": [" "],
      "P_WEATHER": [" "],
    }
    const [currentUnits, setCurrentUnits] = useState({
      "FF": 'm/s',
      "FFG": "m/s",
      FFG_LOG_50_M: "m/s",
      FF_LOG_50_M: "m/s",
      FFDIR_LOG_50_M: "°",
      "FFDIR": "°",
      PPPP: " ",
      T: "°C",
      T0: "°C",
      //WW: " ",
      Sicht: "km",
      RH: "%",
      NCL: "%",
      CUR: "m/s",
      CURDIRTO: "°",
      "W-HSSW": "m",
      "W-DMEAN": "°",
      "W-PMEAN": "s",
      "W-WSHMAX": "m",
      "W-PSEA": "s",
      "W-DSW": "°",
      "W-PSW": "s",
      "W_HSSW": "m",
      "W_HSSW": "m",
      "W_DMEAN": "°",
      "W_PMEAN": "°",
      "W_WSHMAX": "m",
      "W_PSEA": "s",
      "W_DSW": "°",
      "W_PSW": "s",
      "W_HSW": "m",
      SST: "°C",
      //U: " ",
      //V: " ",
      RRR1: "mm",
      RRR3: "mm",
      RRR6: "mm",
      RRR12: "mm",
      RRR24: "mm",
      SNOWF1: "mm",
      SNOWF6: "mm",
      SNOD: "m",
      //"P-WEATHER": " ",
      //"P_WEATHER": " ",
    });


function decimalToDMS(decimal, isLatitude) {
  const degrees = Math.floor(decimal);
  const minutesFloat = (decimal - degrees) * 60;
  const minutes = Math.floor(minutesFloat);
  const seconds = (minutesFloat - minutes) * 60;

  const direction = isLatitude
      ? (degrees >= 0 ? 'N' : 'S')
      : (degrees >= 0 ? 'E' : 'W');

  // return {
  //     degrees: Math.abs(degrees),
  //     minutes: Math.abs(minutes),
  //     seconds: Math.abs(seconds),
  //     direction: direction
  // };
  let grad = Math.abs(degrees) + '°' + Math.abs(minutes) + '\'' + Math.abs(seconds).toFixed(1) + '"' + direction
    return grad
}
function dmsToDecimal(dms) {
  const regex = /(\d+)°(\d+)'(\d+(\.\d+)?)"([NSEW])/;
  const match = dms.match(regex);

  if (!match) {
      throw new Error("Ungültiges DMS-Format");
  }

  const degrees = parseInt(match[1]);
  const minutes = parseInt(match[2]);
  const seconds = parseFloat(match[3]);
  const direction = match[5];

  let decimal = degrees + minutes / 60 + seconds / 3600;

  if (direction === 'S' || direction === 'W') {
      decimal *= -1;
  }

  return decimal;
}


 const lonValue = useRef('')
 const latValue = useRef('')


const newPosition = {
  lat: 54.4,
  lng: 10.2,
}
const newPositionGrad ={
  lat: "54°27'36.0\"N",
  lng: "10°21'36.0\"E",
}

 const lonValueGrad = useRef('')
 const latValueGrad = useRef('')
 const LocationFinderDummy = () => {
  const map = useMapEvents({
      click(e) {
        //console.log(e.latlng);
        latValue.current.value = Math.round(100*e.latlng.lat)/100
        lonValue.current.value = Math.round(100*e.latlng.lng)/100
        latValueGrad.current.value = decimalToDMS(Math.round(100*e.latlng.lat)/100, true)
        lonValueGrad.current.value = decimalToDMS(Math.round(100*e.latlng.lng)/100, false)
        //console.log(latValueGrad.current.value.degrees)
        //console.log(`Latitude: ${latitudeDMS.degrees}°${latitudeDMS.minutes}'${latitudeDMS.seconds.toFixed(1)}"${latitudeDMS.direction}`);
        //console.log(decimalToDMS(Math.round(100*e.latlng.lat)/100, true))
        //console.log(latValueGrad.current.value)
        //console.log(latValue.current.value)
        newPosition.lat = latValue.current.value
        newPosition.lng = lonValue.current.value
        newPositionGrad.lat = latValueGrad.current.value
        newPositionGrad.lng = lonValueGrad.current.value
        //newPositionGrad.lat = 
        //newPositionGrad.lat = latValueGrad.degrees + '°' + latValueGrad.minutes + '\'' + latValueGrad.seconds + '\"' + latValueGrad.direction
        //console.log(newPositionGrad)
        setPositionGrad(newPositionGrad)
        //console.log(newPositionGrad)
        setPosition(newPosition)
        //console.log(newPosition)

      },
  });
  return null;
};

  const handlelatlonChange = () => {
    //console.log(lonValue.current.value)
    //console.log(lonValueGrad.current.value)
    //console.log(latValue.current.value)
    //console.log(Math.round(100*latValue.current.value)/100)
    newPosition.lat = Math.round(100*latValue.current.value)/100
    newPosition.lng = Math.round(100*lonValue.current.value)/100
    
    //newPositionGrad.lat = decimalToDMS(Math.round(100*latValue.current.value)/100, true)
    //newPositionGrad.lng = decimalToDMS(Math.round(100*lonValue.current.value)/100, false)
    newPositionGrad.lat = latValueGrad.current.value
    newPositionGrad.lng = lonValueGrad.current.value
    let test = dmsToDecimal(newPositionGrad.lat)
    newPosition.lat = Math.round(100*dmsToDecimal(newPositionGrad.lat))/100
    newPosition.lng = Math.round(100*dmsToDecimal(newPositionGrad.lng))/100
    //console.log(test)
    //console.log(newPositionGrad)
    setPositionGrad(newPositionGrad)
    setPosition(newPosition)
}
const [singleSpotData, setsingleSpotData] = useState(null)
//  wwPdsSingleTest(request: {lonlat: [10.3, 54.5], hour_from: 0, hour_to: 9, minute_step: 60, req1: {model: "ICONDE", param1: ["FF", "m/s", "numeric(5,1)"]}, req2: {model: "ICONDE", param2: ["FFDIR","°","integer"]}, req3: {model: "ICONDE", param3: ["T","°C","numeric(5,1)"]}, req4: {model: "ICONDE", param4: ["FF50","m/s","numeric(5,1)"]}}
//wwPdsSingleTest(request: {lonlat:[10.3,54.5],hour_from:0,hour_to:9,minute_step:60,req1:{model:"ICONDE",param1:["FF","m/s","numeric(5,1)"]}}

const [checkboxes, setCheckboxes] = useState({
  FF: false,
  FFG: false,
  FFDIR: false,
  T: false,
  checkbox5: false,
  checkbox6: false,
  // checkbox7: false,
  // checkbox8: false,
  // checkbox9: false,

});

// Handler zum Ändern des Status einer Checkbox
const handleChange = (event) => {
  const { name, checked } = event.target;
  setCheckboxes({
    ...checkboxes,
    [name]: checked,
  });
}
// const checkboxGroups = [
//   [t("table_header_param_FF"), t("table_header_param_FFG"), t("table_header_param_FFDIR")],
//   [t("table_header_param_T"), t("table_header_param_W-HSSW"), t("table_header_param_W-DMEAN")],
//   // ['Sichtweite', 'Wettercode', 'Wolkenbedeckung'],
// ];
const checkboxGroups = [
  ["FF", "FFG", "FFDIR"],
  ["T", "W-HSSW", "W-DMEAN"],
  // ['Sichtweite', 'Wettercode', 'Wolkenbedeckung'],
];

const getCheckedCheckboxes = () => {
  return Object.entries(checkboxes)
    .filter(([key, value]) => value === true) // Nur markierte Checkboxen filtern
    .map(([key]) => key); // Nur die Namen der Checkboxen zurückgeben
};
//let params = []
const checkedCkeckboxes = getCheckedCheckboxes()

const [buttonClicked, setButtonClicked] = useState(false)
const handlesinglerequest= () => {
  

  setButtonClicked(true)
  console.log(checkedCkeckboxes)
  
let parameterString = ""
  for (let i=1; i < checkedCkeckboxes.length +1; i++) {
    // params.push("param"+i)
    // console.log(params)
    parameterString += ', req' + i  + ': {model: '
    switch (true) {
      case checkedCkeckboxes[i-1] === "FF":
      parameterString += '"ICON", param' + i + ': ["FF", "m/s", "numeric(5,1)"]}';
      break;
      case checkedCkeckboxes[i-1] === "FFG":
      parameterString += '"ICON", param' + i + ': ["FFG","m/s","numeric(5,1)"]}';
      break;
      case checkedCkeckboxes[i-1] === "FFDIR":
      parameterString += '"ICON", param' + i + ': ["FFDIR","°","integer"]}';
      break;
      case checkedCkeckboxes[i-1] === "T":
      parameterString += '"ICON", param' + i + ': ["T", "°C", "numeric(5,1)"]}';
      break;
      case checkedCkeckboxes[i-1] === "W-HSSW":
      parameterString += '"W_COPGLOBAL", param' + i + ': ["W-HSSW","m","numeric(5,1)"]}';
      break;
      case checkedCkeckboxes[i-1] === "W-DMEAN":
      parameterString += '"W_COPGLOBAL", param' + i + ': ["W-DMEAN", "°", "integer"]}';
      break;
      case checkedCkeckboxes[i-1] === 'Sichtweite':
      parameterString += '"ICON", param' + i + ': ["VV", "m", "integer"]}';
      break;
      case checkedCkeckboxes[i-1] === 'WetterCode':
      parameterString += '"ICON", param' + i + ': ["WW", "-", "integer"]}';
      break;
      case checkedCkeckboxes[i-1] === 'Wolkenbedeckung':
      parameterString += '"ICON", param' + i + ': ["NCL", "%", "integer"]}';

    }
    //, req1: {model: "ICON", param1: ["FF", "km/h", "numeric(5,1)"]}

  }

// console.log(params)
  //const METEO_URL ="http://grib-gis-01.local/meteodb-wod/graphql"
  const METEO_URL = "https://api.wetterwelt.de/graphql/wod"
  let paramvaluesTmp = []
  const graphqlQuery = `
  query MyQuery {
    wwPdsSinglePortalRequest(request: {lonlat: [${position.lng},${position.lat}], hour_from: 0, hour_to: 48, minute_step: 60,${parameterString} }
  )}`
  //console.log(graphqlQuerytest)  
  const graphqlQueryStatic = `
  query MyQuery {
    wwPdsSinglePortalRequest(request: {lonlat: [${position.lng},${position.lat}], hour_from: 0, hour_to: 48, minute_step: 60, req1: {model: "ICON", param1: ["FF", "km/h", "numeric(5,1)"]}, req2: {model: "ICON", param2: ["FFG","km/h","numeric(5,1)"]}, req3: {model: "ICON", param3: ["FFDIR","°","integer"]}, req4: {model: "W_COPGLOBAL", param4: ["W-HSSW","m","numeric(5,1)"]}, req5: {model: "ICON", param5: ["T", "°C", "numeric(5,1)"]}}
  )}`
  //console.log(graphqlQuery)
 PostGraphileApi.fetchRequestWithTokenRefresh(
  METEO_URL,
  graphqlQuery,
  accessContext,
  setAccessContext,
  ".loadGraphql() fehler_1="
)
.then(({ data }) => {
  // console.log('###eca',data.ecaGeojsons.nodes);
  // console.warn(JSON.stringify(data))
  //console.log(data.wwPdsSinglePortalRequest.id1)
  setsingleSpotData(data.wwPdsSinglePortalRequest)
  //console.log(singleSpotData)
  for (let i in data.wwPdsSinglePortalRequest) {
    paramvaluesTmp.push([i, data.wwPdsSinglePortalRequest[i]])
  
  }
  // for (let i in singleSpotData) {
  //   paramvalues.push([i, singleSpotData[i]])
  
  // }
  // tmpvalues = paramvalues.slice(3).sort()
  // //console.log(tmpvalues)
  let sortedParamValue= paramvaluesTmp.slice(3).sort()
  let testvalues = []
  if (sortedParamValue[0]) {  
    for (let i =0; i<sortedParamValue.length; i++) {
      testvalues.push(sortedParamValue[i][1])
    }
  }
  
  setCurrentValues(JSON.parse(JSON.stringify(testvalues)));

  //console.log(currentValues)
})


//console.log(currentValues)
  //  console.log(PostGraphileApi.fetchRequest(
  //   METEO_URL,
  //   PostGraphileApi.httpRequestData(graphqlQuery),
  //   ".loadGraphql() fehler_1="
  // ))

}
let tmpvalues
let paramvalues = []
//console.log(singleSpotData)
//console.log(currentValues)
for (let i in singleSpotData) {
  paramvalues.push([i, singleSpotData[i]])

}

const [position, setPosition] = useState(newPosition)
const [positionGrad, setPositionGrad] = useState(newPositionGrad)
  function DraggableMarker() {

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      
      () => ({
        
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
            latValue.current.value = Math.round(100*position.lat)/100
            lonValue.current.value = Math.round(100*position.lng)/100
            latValueGrad.current.value = decimalToDMS(Math.round(100*position.lat)/100, true)
            lonValueGrad.current.value = decimalToDMS(Math.round(100*position.lng)/100, false)
            //console.log(latValue.current.value)
            //console.log(lonValue.current.value)
          }
        },
      }),
      [],
    )

  
    return (
      <Marker
      icon={new Icon({iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png', iconSize: [25, 41], iconAnchor: [12, 41]})}
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
      </Marker>
    )
  }

let allParams = [
  [ "FFDIR", "Windrichtung 10m", "°" ],
  [ "FF", "Windgeschw. 10m", "m/s" ],
  [ "FFG", "Böen 10m", "m/s" ],
  [ "FFDIR_LOG_50_M", "Windrichtung 50m", "°" ],
  [ "FF_LOG_50_M", "Windgeschwindigkeit 50m", "m/s" ],
  [ "FFG_LOG_50_M", "Böen 50m", "m/s" ],
  [ "W-WSHMAX", "maximale effektive Wellenhöhe", "m" ],
  [ "W-HSSW", "effektive Wellenhöhe", "m" ],
  [ "W-DMEAN", "Wellenrichtung", "°" ],
  [ "W-PMEAN", "effektive Wellenperiode", "s" ],
  [ "W-HSW", "Höhe Dünung", "m" ],
  [ "W-PSW", "Periode Dünung", "s" ],
  [ "W-DSW", "Richtung Dünung", "°" ],
  [ "SST", "Meeresoberflächentemperatur", "K" ],
  [ "T", "Temperatur 2m", "°C" ],
  [ "WW", "WetterCode", "-" ],
  [ "VV", "Sichtweite", "m" ]
]
let params = []
for (let i =0;i<checkedCkeckboxes.length; i++) {
  //console.log(checkedCkeckboxes[i])
  for (let j =0; j< allParams.length; j++) {
    //console.log(allParams[j])
  if( allParams[j].some(item => item === checkedCkeckboxes[i]))
  params.push(allParams[j])
  }
}
//console.log(params)
  const getParamValue = (p, index, row) => {  
    let param = "param" + parseInt(index +1)
    return row[param]
  }

// Parameter Einheiten Umrechnen

let values = []

  tmpvalues = paramvalues.slice(3).sort()
  //console.log(tmpvalues)
  if (tmpvalues[0]) {  
    for (let i =0; i<tmpvalues.length; i++) {
      values.push(tmpvalues[i][1])
    }
  }
console.log(values)
console.log(currentValues)

const [dropdownVisible, setDropdownVisible] = useState(null);

const handleUnitClick = (parameter) => {
  //console.log("Test Calculate Start")
  //if (accessContext.payload.access.includes("Calculate")) {
  setDropdownVisible(dropdownVisible === parameter ? null : parameter);
  //}
};

const handleUnitSelect = (parameter, unit) => {
  //if (accessContext.payload.access.includes("Calculate")) {
  
  setCurrentUnits({
    ...currentUnits,
    [parameter]: unit,
  });



  setDropdownVisible(null); // Dropdown schließen


  updateColumnValues(parameter, unit)
//}

};
//Math.round(number * 10) / 10
//console.log(params)
const updateColumnValues = (parameter, unit) => {
  let paramNumber
  let paramCounter
  for (let i = 0; i < params.length; i++) {

    if (params[i][0] === parameter) {

      paramNumber = "param" + (i+1)
      paramCounter = i
    }
  }

if (params[paramCounter][2] === 'm/s') {
  console.log("test")
  console.log(currentValues)
    setCurrentValues(prevValues => {
      return prevValues.map((row, index) => {
        const tmpRowValue = values[index][paramNumber];
        let updatedValue;

        if (unit === "m/s") {
          updatedValue = String(Number(tmpRowValue[0]))
        } else if (unit === "km/h") {
          updatedValue = String(Math.round(Number(tmpRowValue[0]) * 36) / 10)
        } else if (unit === "kn") {
          updatedValue = String(Math.round(Number(tmpRowValue[0]) * 19.4384) / 10)
        } else if (unit === "bft") {
          let bftnumber
      switch (true) {
        case tmpRowValue[0] < 0.3:
          bftnumber = "0";
        break;
        case tmpRowValue[0] < 1.6:
          bftnumber = "1";
        break;
        case tmpRowValue[0] < 3.4:
          bftnumber = "2";
        break;
        case tmpRowValue[0] < 5.5:
          bftnumber = "3";
        break;
        case tmpRowValue[0] < 8:
          bftnumber = "4";
        break;
        case tmpRowValue[0] < 10.8:
          bftnumber = "5";
        break;
        case tmpRowValue[0] < 13.9:
          bftnumber = "6";
        break;
        case tmpRowValue[0] < 17.2:
          bftnumber = "7";
        break;
        case tmpRowValue[0] < 20.8:
          bftnumber = "8";
        break;
        case tmpRowValue[0] < 24.5:
          bftnumber = "9";
        break;
        case tmpRowValue[0] < 28.5:
          bftnumber = "10";
        break;
        case tmpRowValue[0] < 32.7:
          bftnumber = "11";
        break;
        case tmpRowValue[0] > 32.6:
          bftnumber = "12";
      }
      updatedValue = String(bftnumber)
        }
        return {
          ...row,
          [paramNumber]: [updatedValue],
        };
      });
    });
  console.log(currentValues)
} else if (params[paramCounter][2] === '°') {
  setCurrentValues(prevValues => {
    return prevValues.map( (row, index) => {
      const tmpRowValue = values[index][paramNumber];
      let updatedValue;
   if (unit === '°') {
    updatedValue = String(Number(tmpRowValue[0]));
    }  else if (unit === 'text') {
      let noswText
      switch (true) {
        case tmpRowValue[0] < 23:
        noswText = "N";
        break;
        case tmpRowValue[0] < 68:
        noswText = "NO";
        break;
        case tmpRowValue[0] < 113:
        noswText = "O";
        break;
        case tmpRowValue[0] < 158:
        noswText = "SO";
        break;
        case tmpRowValue[0] < 203:
        noswText = "S";
        break;
        case tmpRowValue[0] < 248:
        noswText = "SW";
        break;
        case tmpRowValue[0] < 293:
        noswText = "W";
        break;
        case tmpRowValue[0] < 338:
        noswText = "NW";
        break;
        case tmpRowValue[0] < 361:
        noswText = "NW";

      }
      updatedValue = String(noswText)
  }
      return {
        ...row,
        [paramNumber]: [updatedValue],
      };
    });
  })


} else if (params[paramCounter][2] === '°C') {
  setCurrentValues(prevValues => {
    return prevValues.map((row, index) => {
      const tmpRowValue = values[index][paramNumber];
      let updatedValue;
  if (unit === '°C') {
    updatedValue = String(Number(tmpRowValue[0]));
    } else if (unit === '°K') {
    updatedValue = String(Number(tmpRowValue[0]) + 273)
    }
    return {
      ...row,
      [paramNumber]: [updatedValue],
    };
  });
})

}


};

// [t("table_header_param_FF"), t("table_header_param_FFG"), t("table_header_param_FFDIR")],
// [t("table_header_param_T"), t("table_header_param_W-HSSW"), t("table_header_param_W-DMEAN")],

const getnewColumnHeader = (param) => {
  if (param === t("table_header_param_FF")) {
    return t("table_header_param_FF") + " m/s"
  } else if (param === t("table_header_param_FFG")) {
    return t("table_header_param_FFG") + " m/s"
  } else if (param === t("table_header_param_FFDIR")) {
    return t("table_header_param_FFDIR") + " °"
  } else if (param === t("table_header_param_T")) {
    return t("table_header_param_T") + " °C"
  } else if (param === t("table_header_param_W-HSSW")) {
    return t("table_header_param_W-HSSW") + " m"
  } else if (param === t("table_header_param_W-DMEAN")) {
    return t("table_header_param_W-DMEAN") + " °"
  }

}

//Parameter Einheiten Umrechnen Ende
    return (
    <div >

      <Grid container spacing={2}>
        <Grid item>
        <MapContainer center={[54.326914, 10.12249]} zoom={10} style={{height: "40vh", width: "40vw"}} >
        <LocationFinderDummy />
      {/* OPEN STREEN MAPS TILES */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <DraggableMarker />
      </MapContainer>

        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography  gutterBottom variant="subtitle1" component="div">
                {t("spotweather_configuration")}
              </Typography>
              <Typography variant="body2" gutterBottom >
                {t("location")}
              </Typography>
              <TextField
                variant="standard" 
                id="lon" label="Longitude"
                helperText=''
                defaultValue={"10°21'36.0\"E"}
                inputRef={lonValueGrad}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="lat" label="Latitude"
                helperText=''
                defaultValue={"54°27'36.0\"N"}
                inputRef={latValueGrad}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="lonOld" label="Longitude"
                type="hidden"
                helperText=''
                defaultValue={10.2}
                inputRef={lonValue}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="latOld" label="Latitude"
                type="hidden"
                helperText=''
                defaultValue={54.4}
                inputRef={latValue}
                onChange={handlelatlonChange}
              />
              <Typography variant="body2">
                
              </Typography>

              {/* <Button onClick={() => generatePDF(tablePDF, {filename: 'page.pdf'})} >
                PDF
              </Button> */}


            </Grid>
            <Grid item xs>
            <FormControl component="fieldset">
              <Typography>{t("param_selection")}</Typography>
      <Grid container spacing={2}>
        {checkboxGroups.map((group, index) => (
          <Grid item xs={6} key={index}>
            <FormGroup>
              {group.map((key) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                    checked={Boolean(checkboxes[key])}
                      onChange={handleChange}
                      name={key}
                    />
                  }
                  label={t("table_header_param_"+key)}
                />
              ))}
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </FormControl>
    </Grid>
    <Grid container spacing={2}>
    <Button style={{ margin: '40px' }} variant="contained" onClick={handlesinglerequest} >
              {t("button_submit")}
              </Button>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table ref={tablePDF} sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{ t("table_header_time_utc") }</TableCell>
            {checkedCkeckboxes.map((name) => (
              <TableCell align="right" onClick={() => handleUnitClick(name)} style={{
                cursor:  "pointer",
              }}>{t("table_header_param_"+name)} ({currentUnits[name]})
              {dropdownVisible === name && (
                        <div >
                        {parameterUnits[name].map((unit) => (
                          <div
                      key={unit}
                      
                          onClick={() => handleUnitSelect(name, unit)}
                        >
                      {unit}
                      </div>
                    ))}
                  </div>
                  )}
                      </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentValues.map((value) => (
            <TableRow
              key={value[0]}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {value.forecast_time.slice(8,10) + "." + value.forecast_time.slice(5,7) + "." + value.forecast_time.slice(0,4) + "   " + value.forecast_time.slice(11,16)}
              </TableCell>
              {checkedCkeckboxes.map((p,index) => (
              <TableCell align="right">{getParamValue(p, index, value)}</TableCell>
              ))}
              {/* <TableCell align="right">{value[1].param1}</TableCell>
              <TableCell align="right">{value[1].param2}</TableCell>
              <TableCell align="right">{value[1].param3}</TableCell>
              <TableCell align="right">{value[1].param4}</TableCell>
              <TableCell align="right">{value[1].param5}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    );
}

const SingleSpotweatherTeaser = ({update}) => {
  const myStyles = useStyles()
  const { accessContext, setAccessContext } = useAccessContext()
  const [singleSpotweatherImage, setSingleSpotweatherImage] = useState({ src: "images/Punktabfrage_teaser.png"})

  // useEffect(() => {   // init teaser with first image
  //   setSingleImage(accessContext, accessContext.configu, 0, setSingleSpotweatherImage)
  // })

  return (
    <div>
        { singleSpotweatherImage && (
            <CardMedia
              className={myStyles.dashboardCardMedia}
              component="img"
              image={"images/Punktabfrage_teaser.png"}
            />
        ) }
    </div>
  )
}

export { SingleSpotweatherTeaser, SingleSpotweather }