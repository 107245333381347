import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from "react-i18next"

//import { Typography, CardMedia, Grid } from '@mui/material';

import { useAccessContext } from '../../modules/AccessContext'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "leaflet/dist/leaflet.css";
import { Typography } from '@mui/material';
//import proj4 from 'proj4';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 400,
      maxWidth: 800,
      //whiteSpace: 'nowrap',
    },
  });

const Info = ( { styles, playerInterval, update } ) => {
    const { t } = useTranslation()
    const accessContext = useAccessContext()
    console.log(accessContext.payload.spotweatherRequests[0].spotweatherParameters.nodes)
    const classes = useStyles();
    const windskala = [["Windskala","Bft","Kn","Km/h","m/s","Hurrikan-Skala"],["windstill","0","0","0","0","\\"],["schwacher Wind","1","1..2","2..4","1","\\"],["-\"-","2","3..5","5..9","2","\\"],["-\"-","3","6..10","10..19","3..5","\\"],["mäßiger Wind","4","11..15","20..29","6..7","\\"],["frischer Wind","5","16..20","30..38","8..10","\\"],["starker Wind","6","21..26","39..49","11..13","\\"],["stark.-stürm. Wind","7","27..33","50..62","14..17","Tropische Depression"],["stürmischer Wind","8","34..40","63..75","18..21","Tropischer Sturm"],["Sturm","9","41..47","76..88","22..24","-\"-"],["schwerer Sturm","10","48..55","89..103","25..28","-\"-"],["orkanartiger Sturm","11","56..63","104..118","29..32","-\"-"],["Orkan","12","64..82","119..153","33..42","Kategorie 1"],["\\","\\","83..96","154..179","43..49","Kategorie 2"],["\\","\\","97..114","180..212","50..58","Kategoie 3"],["\\","\\","115..135","213..252","59..70","Kategorie 4/Supertaifun"],["\\","\\",">136",">253",">71","Kategorie 5"]]
    const requestParameters = accessContext.payload.spotweatherRequests[0].spotweatherParameters.nodes
    let warnings
    let params
    for (let i = 0; i < requestParameters.length; i++) {
        if ( requestParameters[i].type === "warn_expressions_colors") {
            warnings = requestParameters[i]
        } else if ( requestParameters[i].type === "param_name_fullname_unit_typeformat") {
            params = requestParameters[i]
        }
    }
    let warnParams = {}
    let paramKeys = []
    const findLimitEntries = (obj) => {

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                const limitEntry = obj[key].find(item => /^limit:\d+$/.test(item));
                if (limitEntry) {

                    const limitValue = limitEntry.split(':')[1];
                    warnParams[key] = [limitValue];
                    paramKeys.push(key)
                }
            }
        }
        console.log(warnParams)
        console.log(paramKeys[0])

    }
findLimitEntries(warnings)
    console.log(paramKeys)
    
    return (
        <div>
        <Typography>Ihre persönlichen Warnlimits</Typography>
        {/* <Typography>{params.paramKeys[0]}</Typography> */}
        {/* <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
            <TableRow key={"myheader"}>
            {params.map((header,i) => (
            <StyledTableCell align={(i===0)?"left":"right"}>{header}</StyledTableCell>
            ))}
            </TableRow>
        </TableHead>
        </Table> */}

        <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
            <TableRow key={"myheader"}>
            {windskala[0].map((header,i) => (
            <StyledTableCell align={(i===0)?"left":"right"}>{header}</StyledTableCell>
            ))}
            </TableRow>
        </TableHead>
        <TableBody>
          {windskala.slice(1).map((row,i) => (
            (i===0)?              
            ""
            :
           <StyledTableRow > 
           {row.map((col,i) => (
             (col==="\\")?
             <StyledTableCell align="right">{""}</StyledTableCell>
             :
             (col==="-\"-")?
             ""
             : <StyledTableCell align={(i===0)?"left":"right"} rowSpan={(col==="schwacher Wind")?"3":(col==="Tropischer Sturm")?"4":"1"}>{col}</StyledTableCell>
           ))}
           </StyledTableRow>
          ))}
        </TableBody>
    </Table>
        </div>
        )
    }
    
    
    
    export { Info }